<template>
  <div>
    <h3 class="section-title first-section-title">
      {{ $t("payment.section1") }}
      <span class="payment-popup">
        <img src="../assets/info.svg" />
        <div class="popup-body">
          <p class="popup-title">{{ $t("payment.pleaseNote") }}</p>
          <div class="popup-text">{{ $t("payment.popupBody") }}</div>
        </div>
      </span>
    </h3>
    <section class="section choosePayment">
      <p
        v-if="selectedPaymentMethodOption === null"
        class="choosePaymentPlaceholder"
      >
        {{ $t("payment.section1") }}
      </p>
      <p v-else class="choosePaymentPlaceholder">
        <span class="payment-selected-method">
          <img
            :src="paymentMethodOptionsIcons[selectedPaymentMethodOption]"
            alt="currency icon"
          />
          {{ selectedOptionLabel }}
          <strong>{{ getPaymentMethodLabel(selectedOptionLabel) }}</strong>
        </span
        >
      </p>
      <button @click="open">{{ $t("payment.edit") }}</button>
    </section>
    <div v-if="opened" class="payment-modal">
      <div class="payment-modal-body">
        <p class="payment-modal-title">{{ $t("payment.section1") }}</p>
        <p class="payment-modal-subtitle">
          {{ $t("payment.section1subtitle") }}
        </p>
        <div class="payment-method-options">
          <div
            v-for="option in sortedPaymentMethods"
            :class="calculatePaymentMethodOptionClass(option.value)"
            :key="option.value"
            @click="selectPaymentMethod(option.value)"
          >
            <div class="payment-method-option-container">
              <img
                :src="paymentMethodOptionsIcons[option.value]"
                alt="currency icon"
              />
              <span>
                {{ option.label }}
                <strong>{{ getPaymentMethodLabel(option.label) }}</strong>
              </span>
            </div>
            
            <div v-if="option.value === 98 || option.value === 89" class="w-100 d-flex justify-end">
              <img src="../assets/visa-mastercard.svg" alt="visa mastercard icon">
            </div>
          </div>
        </div>
        <div class="payment-modal-controls">
          <button class="outlined-button" @click="cancel">
            {{ $t("payment.cancel") }}
          </button>
          <button class="button" @click="submit">
            {{ $t("payment.submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const paymentMethodOptionsIcons = {
  99: require("../assets/bitcoin.svg"),
  100: require("../assets/usdt.svg"),
  89: require("../assets/toperpay.svg"),
  97: require("../assets/bitget.svg"),
  98: require("../assets/moonpay-icon.svg"),
  92: require("../assets/coinsmart.svg"),
  94: require("../assets/crypto.svg"),
  96: require("../assets/bybit.svg"),
  95: require("../assets/binance.svg"),
  93: require("../assets/shakepay.svg"),
  90: require("../assets/cash-app.svg"),
  91: require("../assets/venmo.svg"),
};

export default {
  props: {
    paymentMethodsOptions: Array,
    selectedPaymentMethodOption: [Number, null],
  },

  data() {
    return {
      opened: false,
      methodToSelect: null,
    };
  },

  methods: {
    open() {
      this.methodToSelect = this.selectedPaymentMethodOption;
      document.documentElement.style.overflow = "hidden";
      this.opened = true;
    },
    cancel() {
      document.documentElement.style.overflow = "";
      this.opened = false;
      this.methodToSelect = null;
    },
    submit() {
      this.$emit("methodSelect", this.methodToSelect);
      document.documentElement.style.overflow = "";
      this.opened = false;
    },
    calculatePaymentMethodOptionClass(value) {
      const classes = ["payment-method-option"];
      if (this.methodToSelect === value) {
        classes.push("payment-method-option-selected");
      }
      return classes.join(" ");
    },
    selectPaymentMethod(method) {
      this.methodToSelect = method;
    },
    getPaymentMethodLabel(name) {
      switch (name) {
        case "Topper Pay":
        case "Moonpay":
          return `- Credit / Debit Card`;
        case "Venmo":
        case "Cash App":
          return `- for USA`;
        case "Shakepay":
          return `- for Canada`;
        default:
          return '';
      }
    },
  },

  computed: {
    paymentMethodOptionsIcons() {
      return paymentMethodOptionsIcons;
    },
    sortedPaymentMethods() {
      const order = ["Moonpay", "Topper Pay", "Cash App", "BTC", "USDT (Polygon)"];
      return [...this.paymentMethodsOptions].sort((a, b) => {
        const indexA = order.indexOf(a.label);
        const indexB = order.indexOf(b.label);

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        return a.label.localeCompare(b.label);
      });
    },
    selectedOptionLabel() {
      return this.sortedPaymentMethods.find(
        ({ value }) => value === this.selectedPaymentMethodOption
      ).label;
    },
  }
};
</script>

<style scoped src="../style.css"></style>
